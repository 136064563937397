<template>
    <span class="text-center" :style="{height: `${heightImage}px`}">
        <v-img src="@/assets/account_circle.svg" contain :height="heightImage" :width="heightImage" v-if="!src || src === 'null'"></v-img>
        <div class="avatar-mask" :style="{width: `${heightImage}px`, height: `${heightImage}px`}" v-else>
            <v-img class="avatar-img" :src="getImgSrc(src)" :height="heightImage">
                <template v-slot:placeholder>
                    <v-row class="fill-height mt-0" justify="center" align-content="center">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </v-row>
                </template>
            </v-img>
        </div>
    </span>
</template>

<script>
    import utilitiesMixin from '@/mixins/utilitiesMixin.js'
    export default {
        name: 'ProfilePhoto',
        mixins: [utilitiesMixin],
        props: {
            src: {
                type: String,
                default: ''
            },
            heightImage: {
                type: String,
                default: "200"
            }
        }
    }
</script>

<style lang="scss" scoped>
.avatar-mask {
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  display: inline-block;
}
.avatar-img {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: auto;
  -webkit-transform: translate(-50%,-50%);
      -ms-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
}
</style>