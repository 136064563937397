<template>
    <v-snackbar
        v-model="snackbar"
        top
        color="primary"
        :timeout="2000"
        @click="snackbar=false"
        >
        <span style="width: 100%;" class="text-center subtitle-1">{{ text }}</span>
    </v-snackbar>
</template>

<script>
    export default {
        name: "SnackBar",
        props: {
            snackbarOpen: {
                type: Boolean,
                required: true
            },
            text: {
                type: String,
                default: 'snackbar text'
            }
        },
        data() {
            return {
                snackbar: this.snackbarOpen
            }
        },
        watch: {
            snackbarOpen: function() {
                this.snackbar = this.snackbarOpen
            },
            snackbar: function() {
                if (this.snackbar == false) {
                    this.$emit('snackBarClosed')
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>