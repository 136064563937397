<template>
    <v-dialog
        v-model="dialog"
        width="500"
        persistent
    >
    <v-card>
        <div v-if="this.$store.state.loggedInFlag == '1'">
            <v-card-title primary-title>
                <v-icon large class="mr-3 primary--text">insert_invitation</v-icon><span class="display-1 primary--text">{{ $t("inviteDialog.invitation") }}</span>
            </v-card-title>

            <v-card-text class="subtitle-1">
            <div>{{ $t("inviteDialog.invitationText") }}:</div>
            <span v-if="events.length">
                <div v-for="(event, index) in events" :key="index">
                    <v-hover v-slot:default="{ hover }">
                        <v-row :style="[chosen==event.ID_offer ? 'background-color: #E8F5E9;' : hover ? 'background-color: #EEEEEE;' : '', 'cursor: pointer;', 'border-bottom: 1px solid #E0E0E0;']" 
                        @click="chosen == event.ID_offer ? chosen = -1 : chosen = event.ID_offer">
                            <v-col cols=auto>
                                {{ event.title }}
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols=auto v-if="chosen == event.ID_offer">
                                <v-icon color="green">done</v-icon>
                            </v-col>
                        </v-row>
                    </v-hover>
                </div>
            </span>
            <div v-else>
                {{ $t("inviteDialog.notFound") }}
            </div>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="dialog = false; $emit('dialogCancel')"
                class="ma-2"
            >
                {{ $t("inviteDialog.cancel") }}
            </v-btn>
            <v-btn
                v-if="chosen != -1"
                color="primary"
                @click="dialog = false; $emit('dialogOk', chosen)"
                class="ma-2"
            >
                {{ $t("inviteDialog.confirm") }}
            </v-btn>
            </v-card-actions>
        </div>
        <div v-else>
            {{ $t("inviteDialog.error") }}
        </div>
    </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "ErrorDialog",
        props: {
            dialogOpen: {
                type: Boolean,
                default: false
            },
            events: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                dialog: this.dialogOpen,
                chosen: -1
            }
        },
        methods: {
            
        },
        watch: {
            dialogOpen: function() {
                this.dialog = this.dialogOpen
                this.chosen = -1
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>