<template>
        <v-expansion-panel>
            <v-expansion-panel-header :class="$vuetify.breakpoint.smAndDown ? 'px-3 pt-1 pb-0' : ''">
                <v-row>
                    <v-col cols=auto align-self="center">
                        <ProfilePhoto :src="user.photo" heightImage="50" />
                    </v-col>
                    <v-col cols=auto md=auto align-self="center" :class="$vuetify.breakpoint.smAndDown ? 'pl-0' : ''">
                        <div :class="[$vuetify.breakpoint.smAndDown ? 'title' : 'headline', 'font-weight-medium', 'primary--text']">{{ user.name }}</div>
                    </v-col>
                    <v-col cols=12 md=auto align-self="center">
                        <v-icon class="primary--text mr-2" small>near_me</v-icon>
                        <span >{{findRegionNameByCode(user.region)}}</span>
                    </v-col>
                    <v-col cols=12 md=12 align-self="center" :class="['pa-0', 'pl-5', $vuetify.breakpoint.smAndDown ? 'pb-5' : '']">
                        <span v-for="(tag, index) in user.tags" :key="index">
                            <Tag v-if="tag" :name="tag" :tooltip="true"/>
                        </span>
                    </v-col>
                </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <div class="grey--text caption">{{ $t("userCard.description") }}</div>
                <div :style="$vuetify.breakpoint.smAndDown ? 'line-height: 1.7rem; white-space: pre-wrap;' : 'line-height: 1.8rem; white-space: pre-wrap;'" 
                 :class="[$vuetify.breakpoint.smAndDown ? 'body-2' : '', 'font-weight-regular', 'grey--text', 'text--darken-1', 'text-justify']">{{ user.desc }}</div>
                <v-row class="mt-5 ml-3">
                    <v-col cols=auto>
                        <router-link tag="span" :to="{name: 'WebAppProfile', params: {id: user.ID}}"><v-btn>{{ $t("userCard.profile") }}</v-btn></router-link>
                    </v-col>
                    <v-col cols=auto>
                        <v-btn @click="dialog = true" color="primary">{{ $t("userCard.invite") }}</v-btn>
                        <InviteDialog :dialogOpen="dialog" :events="events" @dialogCancel="dialog = false" @dialogOk="invite"/>
                    </v-col>
                </v-row>                
            </v-expansion-panel-content>
        </v-expansion-panel>
</template>

<script>
import Tag from '@/components/partials/Tag'
import utilitiesMixin from '@/mixins/utilitiesMixin'
import InviteDialog from '@/components/users/InviteDialog'
import ProfilePhoto from '@/components/partials/ProfilePhoto'
import axios from 'axios'
    export default {
        name: 'UserCard',
        mixins: [utilitiesMixin],
        components: {Tag, InviteDialog, ProfilePhoto},
        props: {
            user: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                dialog: false,
                events: []
            }
        },
        methods: {
            invite: function(ID_offer) {
                this.dialog = false
                let data_to_post = {
                    ID_user: this.user.ID,
                    ID_offer: ID_offer
                }
                axios.post('/auth/invite', data_to_post)
                .then(
                    () => {
                        this.$emit('inviteSend')
                    }
                ).catch(error => {
                    if (error.response && (error.response.data == "Access Denied" || error.response.data == "Invalid Token")) {
                        this.$router.push({ name: 'logout' })
                    } else {
                        this.$emit('error', this.$t("invitationError"))
                    }
                })
            },
            getEventData() {
                axios.get('/auth/oz_get_offers')
                .then(response => {
                    this.getEventContent(response.data.offers)
                })
                .catch(error => {
                    if (error.response && (error.response.data == "Access Denied" || error.response.data == "Invalid Token")) {
                        this.$router.push({ name: 'logout' })
                    } else {
                        this.dialog = true
                    }
                })
            },
            getEventContent(ids) {
                axios.post('/get_offers_info', {ID_offers: ids})
                .then(response => {
                    response.data.info.forEach(element => {
                        if (element.isActive && element.linkedUsers < element.demand && new Date(`${element.nextEventDate}T${element.nextEventTime}:00`) >= Date.now()) {
                            this.events.push({
                              ...element,
                              tags: (element.tags || []).map(tag => {
                                if(!this.$store.state.tags[tag]){
                                  const tags = this.$i18n.getLocaleMessage('sk-SK').tags;
                                  const key = Object.keys(tags).find(t => tags[t].name === tag);  // english tag name

                                  return key;
                                } else {
                                  return tag;
                                }
                              })
                            })
                        }
                    });
                })
                .catch(() => {
                    this.dialog = true
                })
            }
        },
        created() {
            if (this.$store.state.loggedInFlag == '1') {
                this.getEventData()
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>