<template>
    <div>
        <v-alert v-if="error" type="error" outlined>{{ error }}</v-alert>
        <PopupDialog :dialogOpen="dialog"/>
        <SnackBar :snackbarOpen="snackbar" :text="$t('users.inviteSent')" @snackBarClosed="snackbar=false"/>
        <v-row>
            <v-col cols=12 md=4>
                <v-btn text class="primary--text plain" @click="toggleFilters()" v-if="$vuetify.breakpoint.smAndDown">
                    <v-icon right class='mr-1'>mdi-filter</v-icon>
                    <span v-text="$t($store.state.showFilters ? 'users.filters.hide' : 'users.filters.show')"></span>
                </v-btn>
                <div v-if="$store.state.showFilters || !$vuetify.breakpoint.smAndDown">
                    <v-btn text class="primary--text plain my-5" @click="resetFilters()">
                        <v-icon right class='mr-1'>remove</v-icon>
                        <span>{{ $t("map.filters.clear") }}</span>
                    </v-btn>
                    <v-card class="py-5 px-5" flat style="border-radius: 20px">
                        <div class="subtitle-1 grey--text text--darken-1 mb-3">{{ $t('users.region.text') }}</div>
                        <v-row class="mx-2">
                            <v-col cols=12 class="pa-0">
                                <InputField
                                    class="mt-3"
                                    :label="$t('users.region.label')"
                                    icon="near_me"
                                    v-model="$store.state.filteredRegion"
                                    inputType="regionAutocomplete"
                                    :regionAll="true"
                                    :regionMy="$store.state.region ? true : false"
                                    :regionSlovakia="false"
                                    :inputValue="$store.state.filteredRegion"
                                />
                            </v-col>
                        </v-row>
                        <v-divider class="mb-3"></v-divider>
                        <v-row class="ma-0 mb-5">
                            <v-col cols=12>
                            <InputField
                                :label="$t('users.tags.label')"
                                icon="local_offer"
                                v-model="$store.state.filteredTags"
                                inputType="selectUserTags"
                                :inputValue="[...$store.state.filteredTags]"
                                :tagsColumn="true"
                            />
                            </v-col>
                        </v-row>
                        <v-divider class="mb-5"></v-divider>
                        <div class="subtitle-1 grey--text text--darken-1">{{ $t('users.nameOrDescription.text') }}</div>
                        <v-row class="mx-2">
                            <v-col cols=12 class="pa-0 mr-2">
                                <InputField
                                    class="mt-3"
                                    :label="$t('users.nameOrDescription.label')"
                                    icon="search"
                                    v-model="$store.state.filteredText"
                                    inputType="text"
                                    :inputValue="$store.state.filteredText"
                                />
                            </v-col>
                        </v-row>
                    </v-card>
                </div>
            </v-col>
            <v-col cols=12 md=8>
                <v-expansion-panels accordion>
                    <UserCard
                        v-for="(user, index) in usersPage" :key="index"
                        :user="user"
                        @error="displayError"
                        @inviteSend="error = ''; snackbar=true"
                    />
                </v-expansion-panels>
                <v-pagination v-if="pageCount" class="mt-3" v-model="$store.state.usersPage" :length="pageCount" total-visible=9></v-pagination>
                <div v-else>{{ $t("users.notFound.text") }}</div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import utilitiesMixin from '@/mixins/utilitiesMixin.js'

import UserCard from '@/components/users/UserCard'
import InputField from '@/components/partials/InputField'
import PopupDialog from '@/components/partials/PopupDialog'
import SnackBar from '@/components/partials/SnackBar'
import axios from 'axios'
    export default {
        name: 'WebAppUsers',
        components: {UserCard, InputField, PopupDialog, SnackBar},
        mixins: [utilitiesMixin],
        data() {
            return {
                error: '',
                dialog: false,
                publicMode: false,
                events: [],
                linkedEventsIds: [],
                requestedEventsIds: [],
                users: [],
                usersPerPage: 10,
                snackbar: false
            }
        },
        computed: {
            pageCount: function() {
                return Math.ceil(this.displayedUsers.length/this.usersPerPage)
            },
            usersPage: function() {
                return this.displayedUsers.slice((this.$store.state.usersPage - 1)*this.usersPerPage, this.$store.state.usersPage*this.usersPerPage)
            },
            displayedUsers: function () {
                return this.orderedUsers.filter(user => {
                    return (!this.$store.state.filteredText ||
                    user.name.toLowerCase().includes(this.$store.state.filteredText.toLowerCase()) ||
                    user.desc.toLowerCase().includes(this.$store.state.filteredText.toLowerCase())) &&
                    (!this.$store.state.filteredRegion || this.$store.state.filteredRegion == "data.allRegions" ||
                    this.filteredRegionCode == user.region) &&
                    (this.$store.state.filteredTags.length == 0 ||
                    this.containsTag(user.tags, this.$store.state.filteredTags))
                })
            },
            filteredRegionCode: function () {
                let region = this.$store.state.filteredRegion
                let code = 101
                if (region == 'data.myRegion') {
                    code = this.$store.state.region
                } else if (region.length && region != 'data.allRegions') {
                    const r = this.findRegionBy('displayValue', region);
                    code = r && r.region && r.region.code;
                }
                return code
            },
            orderedUsers: function() {
                let orderedUsers = [...this.users]
                const filteredTags = this.$store.state.filteredTags
                if (filteredTags.length > 1) {
                    orderedUsers.sort((a, b) => {
                        return this.sharedTags(b.tags, filteredTags) - this.sharedTags(a.tags, filteredTags)
                    })
                }
                return orderedUsers
            }
        },
        methods: {
            getUsersData: function() {
                axios.get('/auth/get_users')
                .then(
                    response => {
                        this.users = response.data.info
                    }
                ).catch(error => {
                    if (error.response && (error.response.data == "Access Denied" || error.response.data == "Invalid Token")) {
                        this.$router.push({ name: 'logout' })
                    } else {
                        if (error.response && error.response.data && error.response.data.message) {
                            const msg =  error.response.data.message
                            if (msg.includes('acc')) {
                                this.$router.push({ name: 'logout' })
                            } else {
                                this.dialog = true
                            }
                        } else {
                            this.dialog = true
                        }
                    }
                })
            },
            toggleFilters() {
                this.showFilters = !this.showFilters
            },
            containsTag (tags, filteredTags) {
                if (tags) {
                    for (let tag of tags) {
                        if (filteredTags.includes(tag)) {
                            return true
                        }
                    }
                }
                return false
            },
            resetFilters () {
                this.$store.state.filteredRegion = 'data.allRegions'
                this.$store.state.filteredTags = []
                this.$store.state.filteredText = ""
            },
            displayError (error) {
                this.error = error
                window.scrollTo(0,0)
            },
            sharedTags(tags1, tags2) {
                let shared = 0
                if (tags1 && tags2 && tags1.length && tags2.length) {
                        [...tags1].forEach(tag => {
                    if (tags2.includes(tag)) {
                        shared += 1
                    }
                    })
                }
                return shared
            }
        },
        watch: {
            displayedUsers: function () {
                this.$store.state.usersPage = 1
            }
        },
        created() {
            if (this.$store.state.loggedInFlag == '2') {
                this.$router.push({name: 'WebAppHome'})
            }
            else if (this.$store.state.loggedInFlag == '1') {
                this.getUsersData()
            } else {
                this.$router.push({
                    name: 'login',
                    query: {
                        users: true,
                        nextUrl: this.$route.fullPath
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
